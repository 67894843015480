<template>
  <ViaggiTreno :title="tLabel('menu_partenze')" :faseOperativa="faseOperativa" />
</template>

<script>
import ViaggiTreno from "./ViaggiTreno";

export default {
  name: 'viaggiTrenoPartenze',
  props: {
    faseOperativa: {
      type: String,
      default: null
    }
  },
  components: {ViaggiTreno}
};
</script>